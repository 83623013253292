/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import Layout from "../components/layout";
import { Link } from "gatsby";
import Container from "../components/container";
import { graphql } from "gatsby";
import PortableTextComponent from "../components/serializers/portableTextComponents";
import { PortableText } from "@portabletext/react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import ProductTeaserSmall from "../components/entities/product/productTeaserSmall";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import ResourceTeaser from "../components/entities/resource/resourceTeaser";

const ResourceByType = (props) => {
  console.log(props);
  const bgImage = convertToBgImage(
    props.data.heroImage.childImageSharp.gatsbyImageData
  );

  return (
    <Layout type="resource-product-index-outer default-outer">
      <SEO
        title={
          props.data.product.nodes[0].headline +
          " Resources - " +
          props.pageContext.resource_type
        }
        lang="en"
        description=""
      ></SEO>

      <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
        <Container>
          <div
            sx={{
              position: "relative",
              zIndex: "2",
              padding: "180px 0px 100px 0px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h1 sx={{ color: "white" }}>
              {props.data.product.nodes[0].headline} Resources
            </h1>
            <div
              sx={{
                a: {
                  color: "white",
                },
                span: {
                  color: "white",
                  margin: "0px 10px",
                },
              }}
            >
              <Link to={"/"}>Home</Link>
              <span>></span>
              <Link to={"/resources"}>Resources</Link>
              <span>></span>
              <Link
                to={"/resources/" + props.data.product.nodes[0].slug.current}
              >
                {props.data.product.nodes[0].headline} Resources
              </Link>
            </div>
          </div>
        </Container>
        <div
          sx={{
            zIndex: "1",
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
            opacity: "0.7",
            background:
              "linear-gradient(to top, #0b5d57 0%, #000000 98%, #000000 100%)",
          }}
        ></div>
      </BackgroundImage>
      <Container className="resources-product-index-container">
        <ul
          sx={{
            padding: "40px 0px",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {props.data.resources.nodes.map((resource, index) => (
            <li
              key={index}
              sx={{
                marginBottom: "40px",
                listStyle: "none",
                width: ["100%", "48%"],
                display: "flex",
              }}
            >
              <ResourceTeaser resource={resource}></ResourceTeaser>
            </li>
          ))}
        </ul>
      </Container>
    </Layout>
  );
};

export default ResourceByType;

export const resourceByTypeQuery = graphql`
  query resourceByTypeQuery($product: String!, $resource_type: String!) {
    blueArrowRight: file(name: { eq: "Right-Blue-Caret" }) {
      publicURL
    }
    blueArrowLeft: file(name: { eq: "Left-Blue-Caret" }) {
      publicURL
    }
    heroImage: file(name: { eq: "freedom" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    product: allSanityProduct(filter: { slug: { current: { eq: $product } } }) {
      nodes {
        headline
        slug {
          current
        }
      }
    }
    resources: allSanityResource(
      sort: { fields: publish_date, order: DESC }
      filter: {
        product: { slug: { current: { eq: $product } } }
        resource_type: { eq: $resource_type }
        _id: { regex: "/^((?!drafts).)*$/" }
      }
    ) {
      nodes {
        title
        slug {
          current
        }
        product {
          slug {
            current
          }
        }
        description
        resource_type
      }
    }
  }
`;
